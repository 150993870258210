.i-mt-40 {
  margin-top: 40px;
}

.i-mt-30 {
  margin-top: 30px;
}

.i-mt-20 {
  margin-top: 20px;
}

.i-mt-10 {
  margin-top: 10px;
}

.i-mb-5 {
  margin-bottom: 5px;
}

.i-mr-10 {
  margin-right: 10px;
}

.i-ml-30 {
  margin-left: 30px;
}

.i-p-4-4 {
  padding: 4px 4px;
}

.i-p-4-8 {
  padding: 4px 8px;
}

.i-p-5 {
  padding: 5px;
}

.i-pb-10 {
  padding-bottom: 10px;
}
